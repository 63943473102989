"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlogItem = void 0;
const m = require("mithril");
const forge = require("forge");
class Internal {
    static onclick = (v) => {
        window.location.href = v.attrs.item.link;
    };
    static shortenItem(v) {
        let short = v.attrs.item['content:encoded'].split(/>\s*</)[0].trim();
        if (short[short.length - 1] != '>')
            short += '>';
        return short;
    }
}
class BlogItem extends forge.kit.LogComponent {
    static oninit(v) { }
    static view(v) {
        return m(forge.fomantic.Item, {
            content: m(forge.fomantic.Content, {
                onclick: () => Internal.onclick(v),
                header: v.attrs.item.title,
                meta: [
                    v.attrs.item.content,
                    ` - ${new Date(v.attrs.item.pubDate).toLocaleDateString()}`
                ]
            }, [m(forge.fomantic.Description, m.trust(Internal.shortenItem(v)))]),
            image: m(forge.fomantic.Image, {
                src: v.attrs.item.enclosure.url,
                onclick: () => Internal.onclick(v)
            })
        });
    }
}
exports.BlogItem = BlogItem;
