"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const m = require("mithril");
const forge = require("forge");
const tf = require("@tensorflow/tfjs");
const Default_1 = require("../layout/Default");
const InputTensor_1 = require("../ML/InputTensor");
const ArithTensor_1 = require("../ML/ArithTensor");
const ListTensors_1 = require("../ML/ListTensors");
const _tensors = [];
class Page extends forge.kit.LogComponent {
    static oninit(v) {
        Page.setLog(v, Page.name, true);
    }
    static onremove(v) {
        if (!!_tensors && !!_tensors.length) {
            v.state.log('Disposing ', _tensors.length, ' tensors');
            _tensors.forEach((t) => tf.dispose());
            forge.shims.L.empty(_tensors);
        }
    }
    static view(v) {
        return m(forge.kit.Segment, m(forge.kit.G, {
            css: '.w-60.m-l-20',
            columns: [
                [
                    {
                        css: '.w-100-less-200-px',
                        r: [
                            m(forge.fomantic.TabularSegment, {
                                aligned: forge.mold.css.ui.bottom,
                                items: [
                                    {
                                        title: 'Create',
                                        children: () => m(InputTensor_1.InputTensor, {
                                            tensors: _tensors
                                        })
                                    },
                                    {
                                        title: 'Arith',
                                        children: () => m(ArithTensor_1.ArithTensor, {
                                            tensors: _tensors
                                        })
                                    }
                                ]
                            }),
                            m(ListTensors_1.ListTensors, {
                                tensors: _tensors
                            })
                        ]
                    }
                ]
            ]
        }));
    }
}
exports.default = {
    onmatch(params) {
        /**
         * Initialize variables, or return a promise to delay render
         * _initialGlobalVariable = Something;
         *
         * return Promise.resolve();
         */
    },
    render(v) {
        return m(Default_1.Default, m(Page));
    }
};
