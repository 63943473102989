"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TensorAsciiImage = void 0;
const m = require("mithril");
const forge = require("forge");
const tf = require("@tensorflow/tfjs");
const AsciiText_1 = require("../content/AsciiText");
const PConfigure = {
    scale: 'scale'
};
class Internal {
    static genAscii(v) {
        const canvas = v.state.canvas;
        tf.tidy(() => {
            const canvasTensor = tf.browser.fromPixels(canvas);
            const grayscale = tf.image.rgbToGrayscale(canvasTensor);
            const asciiChars = [
                '@',
                '#',
                'S',
                '%',
                '?',
                '^',
                '+',
                ';',
                ':',
                ',',
                '.',
                ' '
            ];
            tf.setBackend('cpu'); // Avoid gpu errors, this just ascii
            const [nh, nw] = grayscale.shape.slice(0, 2);
            const scale = v.state.configure.get(PConfigure.scale) * 1;
            const [height, width] = [
                Math.floor(nh * scale - 1 / (nh - 1)),
                Math.floor(nw * scale - 1 / (nw - 1))
            ];
            console.log('height', height, 'width', width);
            const resized = tf.image.resizeNearestNeighbor(grayscale, [height, width], true, false);
            let art = '';
            resized
                .div(255)
                .data()
                .then((d) => {
                for (var i = 0; i < d.length; i++) {
                    if (i % width === 0 && i != 0) {
                        art += '\n';
                    }
                    const idx = Math.floor(d[i] * (asciiChars.length - 1)) || 0;
                    var char = asciiChars[idx];
                    art += char;
                }
                v.state.pre.textContent = art;
            });
        });
    }
    static loadImage(v, e) {
        const canvas = v.state.canvas;
        const ctx = v.state.canvas?.getContext('2d');
        if (!!ctx) {
            const img = new Image();
            img.onload = function () {
                // Set canvas size to image size
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.textBaseline = 'top';
                ctx.imageSmoothingEnabled = false;
                // Clear the canvas
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                // Set the canvas background to white
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                // Draw the image
                ctx.drawImage(img, 0, 0, img.width, img.height);
                Internal.genAscii(v);
            };
            img.src = e.target.result;
        }
    }
}
class TensorAsciiImage extends forge.kit.LogComponent {
    static oninit(v) {
        TensorAsciiImage.setLog(v, TensorAsciiImage.name /* , true */);
        v.state.configure = new forge.entities.Datum({
            scale: 1
        });
    }
    static oncreate(v) {
        v.state.canvas = v.dom.getElementsByTagName('canvas')[0];
        v.state.pre = v.dom.getElementsByTagName('pre')[0];
    }
    static onupdate(v) {
        v.state.canvas = v.dom.getElementsByTagName('canvas')[0];
        v.state.pre = v.dom.getElementsByTagName('pre')[0];
    }
    static view(v) {
        return m(forge.fomantic.Grid, {
            css: forge.mold.css.ui.one +
                forge.mold.css.ui.column +
                forge.mold.css.ui.stackable
        }, m(forge.fomantic.Row, [
            m(forge.fomantic.Column, {}, [
                m(forge.fomantic.H, {
                    level: 3,
                    block: true,
                    css: forge.mold.css.ui.attached + forge.mold.css.ui.top
                }, 'Load an image'),
                m(forge.fomantic.Segment, {
                    attached: true
                }, [
                    m(forge.fomantic.Form, {
                        onsubmit: () => {
                            return false;
                        }
                    }, [
                        m(forge.fomantic.Fields, [
                            m(forge.fomantic.Field, {
                                css: forge.mold.css.ui.ten,
                                label: 'Image to convert'
                            }, [
                                m(forge.mold.css.ui.css +
                                    forge.mold.css.ui.fluid +
                                    forge.mold.css.ui.input, m(forge.fomantic.FileUpload, {
                                    accept: 'image/*',
                                    reader_dataurl_onload: (event) => {
                                        Internal.loadImage(v, event);
                                    }
                                }))
                            ]),
                            m(forge.fomantic.Field, {
                                css: forge.mold.css.ui.six,
                                label: 'Scale'
                            }, m(forge.fomantic.DropDown, {
                                datum: v.state.configure,
                                property: PConfigure.scale,
                                placeholder: '100%',
                                options: [
                                    {
                                        value: '1',
                                        display: '100%'
                                    },
                                    {
                                        value: '.9',
                                        display: '90%'
                                    },
                                    {
                                        value: '.75',
                                        display: '75%'
                                    },
                                    {
                                        value: '.5',
                                        display: '50%'
                                    },
                                    {
                                        value: '.25',
                                        display: '25%'
                                    }
                                ],
                                save: (d, property) => d.autoSave({
                                    property,
                                    fn: (r) => forge.shims.G.saveMockResponse(d).then((r) => {
                                        Internal.genAscii(v);
                                        return r;
                                    })
                                })
                            }))
                        ])
                    ])
                ])
            ])
        ]), m(forge.fomantic.Row, m(forge.fomantic.Column, {
            css: forge.mold.css.ui.sixteen + forge.mold.css.ui.wide
        }, [
            m('canvas', {
                style: 'display: none;'
            }),
            m(AsciiText_1.AsciiText)
        ])));
    }
}
exports.TensorAsciiImage = TensorAsciiImage;
