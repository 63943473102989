"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const m = require("mithril");
const forge = require("forge");
const Default_1 = require("../layout/Default");
const TensorStockPerformance_1 = require("../ML/TensorStockPerformance");
class Page extends forge.kit.LogComponent {
    static oninit(v) {
        Page.setLog(v, Page.name /* , true */);
    }
    static view(v) {
        return m(forge.kit.Segment, m(forge.kit.G, {
            css: '.w-60.m-l-20',
            columns: [
                [
                    {
                        css: '.w-100-less-200-px',
                        r: m(forge.fomantic.TabularSegment, {
                            aligned: forge.mold.css.ui.top,
                            items: [
                                {
                                    title: 'Import',
                                    children: () => m(TensorStockPerformance_1.TensorStockPerformance)
                                }
                            ]
                        })
                    }
                ]
            ]
        }));
    }
}
exports.default = {
    onmatch(params) {
        /**
         * Initialize variables, or return a promise to delay render
         * _initialGlobalVariable = Something;
         *
         * return Promise.resolve();
         */
    },
    render(v) {
        return m(Default_1.Default, m(Page));
    }
};
