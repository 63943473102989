"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageLoader = void 0;
const ImageLoader = {
    load: () => {
        const promises = ImageLoader.imgs.map((url) => new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = resolve;
            img.onerror = reject;
            img.src = url;
        }));
        return Promise.all(promises);
    },
    imgs: [
        'css/media/A1-noid.png',
        'css/media/A1-noid-faded.png',
        'css/media/circle-background-code.png',
        'css/media/Fan.png',
        'css/media/Logo.png',
        'css/media/Right-Gear.png',
        'css/media/Roz.png',
        'css/media/Roz-faded.png',
        'css/media/Small-Gear.png',
        'css/media/Steam.png',
        'css/media/Stu.png',
        'css/media/Stu-faded.png',
        'css/media/Top-Gear.png'
    ]
};
exports.ImageLoader = ImageLoader;
