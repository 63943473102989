"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Splash = void 0;
const m = require("mithril");
const forge = require("forge");
class Splash extends forge.kit.LogComponent {
    static oninit(v) {
        Splash.setLog(v, Splash.name /* , true */);
    }
    static view(v) {
        return m('.splash', [
            m('.top-gear', m(forge.kit.NBSP)),
            m('.left-gear', m(forge.kit.NBSP)),
            m('.right-gear', m(forge.kit.NBSP)),
            m('.small-gear', m(forge.kit.NBSP)),
            m('.circle-background', [
                m('.a1-noid', m(forge.kit.NBSP)),
                m('.stu', m(forge.kit.NBSP)),
                m('.roz', m(forge.kit.NBSP))
            ]),
            m('.steam', m(forge.kit.NBSP)),
            m('.logo', m(forge.kit.NBSP)),
            m('.fan', m(forge.kit.NBSP))
        ]);
    }
}
exports.Splash = Splash;
