"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlogSummary = void 0;
const m = require("mithril");
const forge = require("forge");
const Globals_1 = require("../Globals");
const BlogItem_1 = require("./BlogItem");
const BlogSubscribe_1 = require("./BlogSubscribe");
class BlogSummary extends forge.kit.LogComponent {
    static oninit(v) {
        BlogSummary.setLog(v, BlogSummary.name /*, true*/);
    }
    static view(v) {
        return m(forge.fomantic.Segment, [
            m(forge.fomantic.Segment, { css: forge.mold.css.ui.secondary }, 'Recent blog entries'),
            m(forge.fomantic.Items, { divided: true }, Globals_1.Globals.blog.items.map((item) => m(BlogItem_1.BlogItem, { item }))),
            m(BlogSubscribe_1.BlogSubscribe)
        ]);
    }
}
exports.BlogSummary = BlogSummary;
