"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlogSet = void 0;
const m = require("mithril");
const forge = require("forge");
const BlogFeed_1 = require("./BlogFeed");
class Internal {
    static getItems(v) {
        m.request({
            method: 'GET',
            url: v.attrs.path,
            responseType: 'json'
        }).then((result) => {
            forge.shims.L.repopulate(v.state.items, result.items);
        });
    }
}
class BlogSet extends forge.kit.LogComponent {
    static oninit(v) {
        BlogSet.setLog(v, BlogSet.name, true);
        v.state.items = v.attrs.items || [];
        if (!v.state.items.length && !!v.attrs.path)
            Internal.getItems(v);
    }
    static view(v) {
        return [
            m(forge.fomantic.H, { level: 3 }, v.attrs.title),
            m(forge.fomantic.Segment, { css: forge.mold.css.ui.secondary }, !v.attrs.description
                ? 'An ordered content collection'
                : v.attrs.description),
            m(BlogFeed_1.BlogFeed, { items: v.state.items })
        ];
    }
}
exports.BlogSet = BlogSet;
