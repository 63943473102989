"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TensorAscii = void 0;
const m = require("mithril");
const forge = require("forge");
const tf = require("@tensorflow/tfjs");
const webfont = require("webfontloader");
const AsciiText_1 = require("../content/AsciiText");
const PConfigure = {
    familyName: 'familyName',
    fontSize: 'fontSize',
    text: 'text',
    height: 'height',
    width: 'width'
};
class Internal {
    static loadFont(v) {
        const fontFamily = v.state.configure.get(PConfigure.familyName);
        forge.entities.Toast.info(`Requesting font family ${fontFamily}`);
        webfont.load({
            google: {
                families: [fontFamily] // Replace with your desired font
            },
            fontactive: (familyName, _fvd) => {
                v.state.webfont_loaded = true;
                forge.entities.Toast.info(`Loaded font family ${familyName}`);
                if (familyName === v.state.configure.get(PConfigure.familyName)) {
                    Internal.paintCanvas(v);
                    m.redraw();
                }
            },
            fontinactive: (familyName, _fvd) => {
                v.state.webfont_loaded = false;
                forge.entities.Toast.error(`Unable to load font family ${familyName}`);
            }
        });
    }
    static paintCanvas(v) {
        const canvas = v.state.canvas;
        canvas.height = v.state.configure.get(PConfigure.height);
        canvas.width = v.state.configure.get(PConfigure.width);
        const ctx = canvas.getContext('2d');
        if (!!ctx) {
            ctx.font = `${v.state.configure.get(PConfigure.fontSize)} ${v.state.configure.get(PConfigure.familyName)}`;
            ctx.textBaseline = 'top';
            ctx.imageSmoothingEnabled = false;
            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // Set the canvas background to white
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = 'black';
            ctx.fillText(v.state.configure.get(PConfigure.text), 2, 2);
            tf.tidy(() => {
                const tensor = tf.browser.fromPixels(canvas);
                let grayscaleTensor;
                // If the tensor is RGB
                const [red, green, blue] = tf.split(tensor, 3, 2);
                grayscaleTensor = red.add(green).add(blue).div(tf.scalar(3)).squeeze();
                // Normalize the values to the range [0, 1]
                const normalizedTensor = grayscaleTensor.div(tf.scalar(255));
                const asciiChars = [
                    '@',
                    '#',
                    'S',
                    '%',
                    '?',
                    '^',
                    '+',
                    ';',
                    ':',
                    ',',
                    '.',
                    ' '
                ];
                const [h, w] = normalizedTensor.shape.slice(0, 2);
                let art = '';
                normalizedTensor.data().then((d) => {
                    for (var i = 0; i < d.length; i++) {
                        if (i % w === 0 && i != 0) {
                            art += '\n';
                        }
                        const idx = Math.floor(d[i] * (asciiChars.length - 1)) || 0;
                        var char = asciiChars[idx];
                        art += char;
                    }
                    v.state.pre.textContent = art;
                });
            });
        }
    }
}
class TensorAscii extends forge.kit.LogComponent {
    static oninit(v) {
        TensorAscii.setLog(v, TensorAscii.name, true);
        v.state.configure = new forge.entities.Datum({
            familyName: '',
            fontSize: '30pt',
            height: 75,
            width: 100
        });
    }
    static oncreate(v) {
        v.state.canvas = v.dom.getElementsByTagName('canvas')[0];
        v.state.pre = v.dom.getElementsByTagName('pre')[0];
    }
    static onupdate(v) {
        v.state.canvas = v.dom.getElementsByTagName('canvas')[0];
        v.state.pre = v.dom.getElementsByTagName('pre')[0];
    }
    static view(v) {
        return m(forge.fomantic.Grid, {
            css: forge.mold.css.ui.one +
                forge.mold.css.ui.column +
                forge.mold.css.ui.stackable
        }, m(forge.fomantic.Row, [
            m(forge.fomantic.Column, {}, [
                m(forge.fomantic.H, {
                    level: 3,
                    block: true,
                    css: forge.mold.css.ui.attached + forge.mold.css.ui.top
                }, 'Choose a font'),
                m(forge.fomantic.Segment, {
                    attached: true
                }, [
                    m(forge.fomantic.P, [
                        m(forge.kit.Span, 'Choose a font from '),
                        m('a[href=https://fonts.google.com]', { target: '_new' }, 'fonts.google.com')
                    ]),
                    m(forge.fomantic.Form, {
                        onsubmit: () => {
                            Internal.loadFont(v);
                            return false;
                        }
                    }, [
                        m(forge.fomantic.Fields, m(forge.fomantic.Field, {
                            css: forge.mold.css.ui.ten + forge.mold.css.ui.wide,
                            label: 'Font name'
                        }, [
                            m(forge.mold.css.ui.css +
                                forge.mold.css.ui.fluid +
                                forge.mold.css.ui.input, m(forge.tools.AutoSaveTextBox, {
                                datum: v.state.configure,
                                property: PConfigure.familyName,
                                placeholder: '(i.e. Honk, Dancing Script)',
                                save: (d, property) => d.autoSave({
                                    property,
                                    fn: (_r) => forge.shims.G.saveMockResponse(d).then((r) => {
                                        v.state.webfont_loaded = false;
                                        Internal.paintCanvas(v);
                                        return r;
                                    })
                                })
                            }))
                        ]), m(forge.fomantic.Field, {
                            css: forge.mold.css.ui.three + forge.mold.css.ui.wide,
                            label: 'Size'
                        }, [
                            m(forge.tools.AutoSaveTextBox, {
                                datum: v.state.configure,
                                property: PConfigure.fontSize,
                                placeholder: 'Font size',
                                save: (d, property) => d.autoSave({
                                    property,
                                    fn: (_r) => forge.shims.G.saveMockResponse(d)
                                })
                            })
                        ])),
                        m(forge.fomantic.Fields, [
                            m(forge.fomantic.Field, m(forge.fomantic.Button, {
                                onclick: () => {
                                    Internal.loadFont(v);
                                }
                            }, 'Load Font')),
                            v.state.webfont_loaded
                                ? m(forge.fomantic.Field, {
                                    css: forge.mold.css.ui.five + forge.mold.css.ui.wide
                                }, m(forge.fomantic.H, {
                                    level: 2,
                                    style: `font-family: '${v.state.configure.get(PConfigure.familyName)}'; margin: 0;`
                                }, v.state.configure.get(PConfigure.familyName)))
                                : null
                        ])
                    ])
                ])
            ]),
            m(forge.fomantic.Column, [
                m(forge.fomantic.H, {
                    level: 3,
                    block: true,
                    css: forge.mold.css.ui.css + forge.mold.css.ui.attached
                }, 'Create ascii text'),
                m(forge.fomantic.Segment, {
                    attached: true
                }, m(forge.fomantic.Form, [
                    m(forge.fomantic.Fields, m(forge.fomantic.Field, {
                        css: forge.mold.css.ui.sixteen + forge.mold.css.ui.wide,
                        label: 'Type desired ascii text'
                    }, m(forge.tools.AutoSaveTextBox, {
                        datum: v.state.configure,
                        property: PConfigure.text,
                        save: (d, property) => d.autoSave({
                            property,
                            fn: (_r) => forge.shims.G.saveMockResponse(d).then((r) => {
                                Internal.paintCanvas(v);
                                return r;
                            })
                        })
                    }))),
                    m(forge.fomantic.P, 'The demensions below are the number of characters to allocate for ascii art, if text supplies does not fit, allocate more or less.'),
                    m(forge.fomantic.Fields, [
                        m(forge.fomantic.Field, {
                            css: forge.mold.css.ui.two + forge.mold.css.ui.wide,
                            label: 'Width'
                        }, m(forge.tools.AutoSaveTextBox, {
                            datum: v.state.configure,
                            property: PConfigure.width,
                            inputType: 'number',
                            save: (d, property) => d.autoSave({
                                property,
                                fn: (_r) => forge.shims.G.saveMockResponse(d).then((r) => {
                                    Internal.paintCanvas(v);
                                    return r;
                                })
                            })
                        })),
                        m(forge.fomantic.Field, {
                            css: forge.mold.css.ui.two + forge.mold.css.ui.wide,
                            label: 'Height'
                        }, m(forge.tools.AutoSaveTextBox, {
                            datum: v.state.configure,
                            property: PConfigure.height,
                            inputType: 'number',
                            save: (d, property) => d.autoSave({
                                property,
                                fn: (_r) => forge.shims.G.saveMockResponse(d).then((r) => {
                                    Internal.paintCanvas(v);
                                    return r;
                                })
                            })
                        }))
                    ])
                ]))
            ])
        ]), m(forge.fomantic.Row, m(forge.fomantic.Column, {
            css: forge.mold.css.ui.sixteen + forge.mold.css.ui.wide
        }, [
            m('canvas', {
                style: 'display: none;'
            }),
            m(AsciiText_1.AsciiText)
        ])));
    }
}
exports.TensorAscii = TensorAscii;
