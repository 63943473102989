"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const m = require("mithril");
const forge = require("forge");
const DefaultSplash_1 = require("../layout/DefaultSplash");
const BlogSummary_1 = require("../content/BlogSummary");
const Globals_1 = require("../Globals");
const ImageLaoder_1 = require("../content/ImageLaoder");
const BlogSet_1 = require("../content/BlogSet");
let _crimsonRocketItems;
class Internal {
    static getBlogEntries() {
        // Place into a utility if this is used elsewhere
        const baseUrl = () => {
            /*
            const hostname = window.location.hostname
            if (hostname === 'localhost') {
              return `http://${hostname}:3000`
            }*/
            return '';
        };
        const reqUri = `${baseUrl()}/blog.json`;
        return Promise.all([
            m
                .request({
                method: 'GET',
                url: reqUri
            })
                .then((result) => {
                forge.shims.L.repopulate(Globals_1.Globals.blog.items, result.items);
            }),
            m
                .request({
                method: 'GET',
                url: '/crimson-rocket.json',
                responseType: 'json'
            })
                .then((result) => {
                _crimsonRocketItems = result.items;
            }),
            ImageLaoder_1.ImageLoader.load()
        ]);
    }
}
class Page extends forge.kit.LogComponent {
    static view(v) {
        return m(forge.fomantic.Grid, {
            stackable: true
        }, [
            m(forge.fomantic.Column, {
                size: 6
            }, [
                m(forge.fomantic.Segment, [
                    m(BlogSet_1.BlogSet, {
                        title: 'Crimson Rocket',
                        description: "Roz'lin falls in love with a crimson ship",
                        items: _crimsonRocketItems
                    })
                ])
            ]),
            m(forge.fomantic.Column, { size: 10 }, m(BlogSummary_1.BlogSummary))
        ]);
    }
}
exports.default = {
    onmatch: (_params) => Internal.getBlogEntries(),
    render: (_v) => m(DefaultSplash_1.DefaultSplash, (() => m(Page))())
};
