"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlogFeed = void 0;
const m = require("mithril");
const forge = require("forge");
class BlogFeed extends forge.kit.LogComponent {
    static oninit(v) {
        //BlogFeed.setLog(v, BlogFeed.name/* , true */);
    }
    static view(v) {
        return m(forge.fomantic.Feed, {
            connected: true,
            ordered: true,
            events: v.attrs.items.map((item) => m(forge.fomantic.FeedEvent, {
                summary: m(forge.fomantic.H, {
                    css: forge.mold.css.pointer,
                    level: 4,
                    onclick: () => {
                        console.log(item.link);
                        window.location.href = item.link;
                    }
                }, item.title)
            }))
        });
    }
}
exports.BlogFeed = BlogFeed;
