"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TensorStockPerformance = void 0;
const m = require("mithril");
const forge = require("forge");
class Internal {
    static rollNeuralNetwork(v) { }
    static loadPerformance(v, e) {
        // Clear any previous attempts to load training data
        v.state.performance.splice(0);
        v.state.prediction.splice(0);
        try {
            const dayMillis = 1000 * 60 * 60 * 24;
            const lines = e.target.result.toString().split(/\r?\n/);
            const header = lines[0].split(',');
            // Allow for column order to be out of position.
            const idxDate = header.indexOf('Date'), idxClose = header.indexOf('Close/Last'), idxVolume = header.indexOf('Volume'), idxOpen = header.indexOf('Open'), idxHigh = header.indexOf('High'), idxLow = header.indexOf('Low');
            if ([idxDate, idxClose, idxVolume, idxOpen, idxHigh, idxLow].indexOf(-1) >=
                0) {
                throw new Error('Could not find all columns in file provided');
            }
            const parsePrice = (amt) => {
                let toParse = amt.replace(/\$/g, '');
                if (toParse.match(/[^\d.]/)) {
                    throw new Error(`Unexpected monetary field value ${amt}`);
                }
                return Number.parseFloat(toParse);
            };
            const parseVolume = (amt) => {
                if (!amt.match(/^\d+$/)) {
                    throw new Error(`Unexpected volume supplied ${amt}`);
                }
                // Use float to provide consistent numeric types to the tensors
                return Number.parseFloat(amt);
            };
            const parseDate = (str) => {
                if (str.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
                    return new Date(str);
                }
                throw new Error(`Unexpected date format ${str} expected MM/DD/YYYY`);
            };
            let future = null;
            // Days are in descending order, this loop will skip the header line
            // and will loop through all others; there is likely an extra line in the end,
            // so we will include logic to handle that in parsing a day's data
            for (let dayIdx = 1; dayIdx < lines.length; dayIdx++) {
                const dayEntry = lines[dayIdx].split(',') || [];
                // Create a new day, if and only if all fields are available to be parsed
                const day = dayEntry.length === 6
                    ? {
                        date: parseDate(dayEntry[idxDate]),
                        close: parsePrice(dayEntry[idxClose]),
                        volume: parseVolume(dayEntry[idxVolume]),
                        open: parsePrice(dayEntry[idxOpen]),
                        high: parsePrice(dayEntry[idxHigh]),
                        low: parsePrice(dayEntry[idxLow])
                    }
                    : null;
                // Only create an entry, if we know the future/prediction
                // We need both data and answers to train nuerons
                // (ie training does not include the first entry in performance,
                //     it shows up only in predictions)
                if (!!day && !!future) {
                    // Append the days performance
                    v.state.performance.push(day);
                    // Record metrics that can be used to train predictions
                    v.state.prediction.push({
                        date: day.date,
                        daysBetween: (future.date.getTime() - day.date.getTime()) / dayMillis - 1,
                        nextDate: future.date,
                        close: day.close,
                        futureOpen: future.open,
                        futureClose: future.close,
                        upOpen: future.open > day.open,
                        upClose: future.close > day.close
                    });
                }
                future = day;
            }
            // Pass off processing to creation of a neural network
            Internal.rollNeuralNetwork(v);
        }
        catch (error) {
            console.error(error);
            forge.entities.Toast.error('Unable to process file' + error);
        }
    }
}
class TensorStockPerformance extends forge.kit.LogComponent {
    static oninit(v) {
        TensorStockPerformance.setLog(v, TensorStockPerformance.name /* , true */);
        v.state.performance = [];
        v.state.prediction = [];
    }
    static view(v) {
        return m(forge.fomantic.Grid, {
            css: forge.mold.css.ui.one +
                forge.mold.css.ui.column +
                forge.mold.css.ui.stackable
        }, m(forge.fomantic.Row, [
            m(forge.fomantic.Column, {}, [
                m(forge.fomantic.H, {
                    level: 3,
                    block: true,
                    css: forge.mold.css.ui.attached + forge.mold.css.ui.top
                }, 'Import performance'),
                m(forge.fomantic.Segment, {
                    attached: true
                }, [
                    m(forge.fomantic.P, [
                        m(forge.kit.Span, 'Retrieve a stok history report from '),
                        m('a[href=https://www.nasdaq.com/market-activity/stocks]', { target: '_new' }, 'NASDAQ'),
                        m(forge.kit.Span, '.  Expected CSV contains headers: Date,Close/Last,Volume,High,Low.')
                    ]),
                    m(forge.fomantic.Form, {
                        onsubmit: () => {
                            return false;
                        }
                    }, [
                        m(forge.fomantic.Fields, [
                            m(forge.fomantic.Field, {
                                css: forge.mold.css.ui.ten,
                                label: 'Upload Performance CSV'
                            }, [
                                m(forge.mold.css.ui.css +
                                    forge.mold.css.ui.fluid +
                                    forge.mold.css.ui.input, m(forge.fomantic.FileUpload, {
                                    accept: 'text/csv',
                                    reader_astext_onload: (event) => {
                                        Internal.loadPerformance(v, event);
                                    }
                                }))
                            ])
                        ])
                    ])
                ])
            ])
        ]));
    }
}
exports.TensorStockPerformance = TensorStockPerformance;
