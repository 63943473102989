"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultSplash = void 0;
const m = require("mithril");
const forge = require("forge");
const Splash_1 = require("../visuals/Splash");
const Default_1 = require("./Default");
class DefaultSplash extends forge.kit.LogComponent {
    static oninit(v) {
        DefaultSplash.setLog(v, DefaultSplash.name /* , true */);
    }
    static view(v) {
        return m(Default_1.Default, { css: v.attrs.css }, m(forge.fomantic.Grid, {
            css: forge.mold.css.ui.two + forge.mold.css.ui.columns,
            stackable: true
        }, [
            m(forge.fomantic.Row, { css: '.lazen-bg' }, m(forge.fomantic.Column, m(Splash_1.Splash))),
            m(forge.fomantic.Row, m(forge.fomantic.Column, { css: forge.mold.css.ui.sixteen + forge.mold.css.ui.wide }, v.children))
        ]));
    }
}
exports.DefaultSplash = DefaultSplash;
