"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputTensor = void 0;
const m = require("mithril");
const _ = require("underscore");
const tf = require("@tensorflow/tfjs");
const forge = require("forge");
const PNewTensor = {
    dtype: 'dtype',
    shape: 'shape',
    tensors: 'tensors'
};
class Internal {
    static findDepth(arr, depth = 0) {
        if (Array.isArray(arr) && arr.length > 0) {
            return Internal.findDepth(arr[0], depth + 1);
        }
        return depth;
    }
    static createTensor(v, arr) {
        const depth = Internal.findDepth(arr);
        let tensor;
        let dtype = v.state.new.get(PNewTensor.dtype);
        let shape = JSON.parse(v.state.new.get(PNewTensor.shape));
        switch (Internal.findDepth(arr)) {
            case 1:
                tensor = tf.tensor1d(arr, dtype);
                break;
            case 2:
                tensor = tf.tensor2d(arr, shape, dtype);
                break;
            case 3:
                tensor = tf.tensor3d(arr, shape, dtype);
                break;
            case 4:
                tensor = tf.tensor4d(arr, shape, dtype);
                break;
            case 5:
                tensor = tf.tensor5d(arr, shape, dtype);
                break;
            default:
                v.state.log('error', 'Invalid tensor dimensions');
                throw 'Invalid tensor dimensions';
        }
        v.state.tensors.push(tensor);
    }
    static createClick(v) {
        try {
            let jsn = JSON.parse(v.state.new.get(PNewTensor.tensors));
            if (_.isArray(jsn) && !!jsn.length) {
                Internal.createTensor(v, jsn);
            }
            else {
                v.state.log('error', 'Please supply an array');
            }
        }
        catch (e) {
            v.state.log('error', 'Failed to create tensor:', e);
        }
    }
}
class InputTensor extends forge.kit.LogComponent {
    static oninit(v) {
        InputTensor.setLog(v, InputTensor.name, true);
        v.state.new = new forge.entities.Datum({
            dtype: 'int32',
            shape: '[3, 1]',
            tensors: '[[1], [2], [3]]'
        });
        v.state.tensors = !!v.attrs.tensor
            ? [v.attrs.tensors]
            : v.attrs.tensors || new Array();
    }
    static view(v) {
        return [
            m(forge.fomantic.Form, [
                m(forge.fomantic.Fields, m(forge.fomantic.Field, {
                    css: forge.mold.css.ui.eight + forge.mold.css.ui.wide,
                    label: 'Shape'
                }, m(forge.tools.AutoSaveTextBox, {
                    datum: v.state.new,
                    property: PNewTensor.shape,
                    placeholder: '[1,2 ...6]',
                    save: (d, _) => forge.shims.G.save(d)
                })), m(forge.fomantic.Field, {
                    css: forge.mold.css.ui.eight + forge.mold.css.ui.wide,
                    label: 'Data Type'
                }, m(forge.fomantic.DropDown, {
                    datum: v.state.new,
                    property: PNewTensor.dtype,
                    placeholder: 'Choose data type',
                    save: (d, _) => forge.shims.G.save(d),
                    /*'float32'|'int32'|'bool'|'complex64'|'string'*/
                    options: [
                        {
                            value: 'float32',
                            display: 'float32'
                        },
                        {
                            value: 'int32',
                            display: 'int32'
                        },
                        {
                            value: 'bool',
                            display: 'bool'
                        },
                        {
                            value: 'complex64',
                            display: 'complex64'
                        },
                        {
                            value: 'string',
                            display: 'string'
                        }
                    ]
                }))),
                m(forge.fomantic.Fields, m(forge.fomantic.Field, {
                    css: forge.mold.css.ui.sixteen + forge.mold.css.ui.wide,
                    label: 'New Tensor'
                }, m(forge.tools.AutoSaveTextBox, {
                    datum: v.state.new,
                    property: PNewTensor.tensors,
                    placeholder: '[1,2] or [[1,2], [1,2]]',
                    save: (d, _) => forge.shims.G.save(d)
                }))),
                m(forge.fomantic.Button, {
                    onclick: () => Internal.createClick(v)
                }, 'Create New')
            ])
        ];
    }
}
exports.InputTensor = InputTensor;
