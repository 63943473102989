"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Default = void 0;
const m = require("mithril");
const forge = require("forge");
const AllRoutes_1 = require("../routes/AllRoutes");
class Default extends forge.kit.LogComponent {
    static oninit(v) {
        Default.setLog(v, Default.name /* , true */);
    }
    static view(v) {
        return [
            m(forge.fomantic.Segment, m(forge.fomantic.Container, m(forge.fomantic.Menu, {
                fixed: true,
                items: [
                    m(forge.fomantic.AItem, {
                        href: '#!' + AllRoutes_1.DefaultRouteKeys.Home,
                        onclick: () => m.route.set(AllRoutes_1.DefaultRouteKeys.Home)
                    }, 'Blog'),
                    m(forge.fomantic.AItem, {
                        href: '#!' + AllRoutes_1.DefaultRouteKeys.TensorAscii,
                        onclick: () => m.route.set(AllRoutes_1.DefaultRouteKeys.TensorAscii)
                    }, 'Ascii')
                ]
            }))),
            m(forge.fomantic.Container, m(forge.fomantic.Segment, {
                vertical: true,
                masthead: true,
                aligned: forge.mold.css.ui.center
            }, v.children)),
            m(forge.fomantic.ToastContainer, {
                position_horizontal: forge.mold.css.ui.center,
                position_vertical: forge.mold.css.ui.top
            })
        ];
    }
}
exports.Default = Default;
