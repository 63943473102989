"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlogSubscribe = void 0;
const m = require("mithril");
const forge = require("forge");
class BlogSubscribe extends forge.kit.LogComponent {
    static oninit(v) {
        BlogSubscribe.setLog(v, BlogSubscribe.name /* , true */);
    }
    static view(v) {
        return m(forge.fomantic.Segment, m.trust('<iframe src="https://blog.spill.coffee/embed" width="100%" height="150" style="border:1px solid #EEE; background:white;" frameborder="0" scrolling="no" > </iframe>'));
    }
}
exports.BlogSubscribe = BlogSubscribe;
