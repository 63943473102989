"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArithTensor = void 0;
const m = require("mithril");
const forge = require("forge");
const tf = require("@tensorflow/tfjs");
const PScalers = {
    addition: 'addition',
    subtraction: 'subtraction'
};
class Internal {
    static addForm(children) {
        return m(forge.fomantic.Form, m(forge.fomantic.Fields, m(forge.fomantic.Field, {
            css: forge.mold.css.ui.three +
                forge.mold.css.ui.wide +
                forge.mold.css.ui.css
        }, m(forge.mold.css.ui.css +
            forge.mold.css.ui.action +
            forge.mold.css.ui.input, children))));
    }
}
class ArithTensor extends forge.kit.LogComponent {
    static oninit(v) {
        ArithTensor.setLog(v, ArithTensor.name, true);
        v.state.scalers = new forge.entities.Datum({
            addition: 0,
            subtraction: 0
        });
        v.state.tensors = !!v.attrs.tensor
            ? [v.attrs.tensors]
            : v.attrs.tensors || new Array();
    }
    static view(v) {
        return [
            Internal.addForm([
                m(forge.tools.AutoSaveTextBox, {
                    datum: v.state.scalers,
                    property: PScalers.addition,
                    placeholder: '0',
                    save: (d, p) => {
                        d.update(PScalers.addition, Number.parseInt(d.get(PScalers.addition)));
                        return forge.shims.G.save(d);
                    }
                }),
                m(forge.fomantic.Button, {
                    onclick: () => {
                        const val = v.state.scalers.get(PScalers.addition);
                        const toAdd = tf.scalar(val);
                        forge.shims.L.repopulate(v.state.tensors, v.state.tensors.map((t) => tf.add(toAdd, t)));
                    }
                }, 'Add')
            ]),
            Internal.addForm([
                m(forge.tools.AutoSaveTextBox, {
                    datum: v.state.scalers,
                    property: PScalers.subtraction,
                    placeholder: '0',
                    save: (d, p) => {
                        d.update(PScalers.subtraction, Number.parseInt(d.get(PScalers.subtraction)));
                        return forge.shims.G.save(d);
                    }
                }),
                m(forge.fomantic.Button, {
                    onclick: () => {
                        const val = v.state.scalers.get(PScalers.subtraction);
                        const toSub = tf.scalar(val);
                        forge.shims.L.repopulate(v.state.tensors, v.state.tensors.map((t) => tf.sub(t, toSub)));
                    }
                }, 'Sub')
            ])
        ];
    }
}
exports.ArithTensor = ArithTensor;
