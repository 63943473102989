"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsciiText = void 0;
const m = require("mithril");
const forge = require("forge");
class Internal {
    static copyToClipboard(v) {
        const art = v.state.pre.textContent;
        navigator.clipboard
            .writeText(art || '')
            .then(() => forge.entities.Toast.info('ascii copied to clipboard'))
            .catch(() => forge.entities.Toast.error('Failed to copy to clipboard'));
    }
}
class AsciiText extends forge.kit.LogComponent {
    static oninit(v) {
        AsciiText.setLog(v, AsciiText.name /* , true */);
    }
    static oncreate(v) {
        v.state.pre = v.dom.getElementsByTagName('pre')[0];
    }
    static onupdate(v) {
        v.state.pre = v.dom.getElementsByTagName('pre')[0];
    }
    static view(v) {
        return m(forge.fomantic.Segment, [
            m(forge.fomantic.Menu, {
                style: 'max-width: 145px;',
                css: forge.mold.css.ui.right + forge.mold.css.ui.floated,
                items: [
                    m(forge.fomantic.MenuItem, {
                        onclick: () => Internal.copyToClipboard(v)
                    }, [
                        m(forge.fomantic.Icon, { icon_name: '.copy.outline' }),
                        'Copy ascii text'
                    ])
                ]
            }),
            m('', {
                style: 'overflow-x: auto; width: 100%;'
            }, m('pre', {
                style: "font-family: 'Courier New', monospace; white-space: pre; autoflow-x: auto; font-size: 8pt; line-height: 1;"
            }))
        ]);
    }
}
exports.AsciiText = AsciiText;
