"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListTensors = void 0;
const m = require("mithril");
const forge = require("forge");
class ListTensors extends forge.kit.LogComponent {
    static oninit(v) {
        ListTensors.setLog(v, ListTensors.name /* , true */);
        v.state.tensors = !!v.attrs.tensor
            ? [v.attrs.tensors]
            : v.attrs.tensors || new Array();
    }
    static view(v) {
        return m(forge.fomantic.Table, {
            header: [
                m(forge.fomantic.Th, { colspan: 1 }, `Tensors: ${v.state.tensors.length}`)
            ],
            rows: v.state.tensors.map((t, idx) => [
                m(forge.fomantic.Td, t.toString())
            ])
        });
    }
}
exports.ListTensors = ListTensors;
